import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { parseModelCode } from '../../common'
import { Icon } from 'semantic-ui-react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

import './TimeRemaining.css'
import { clear } from '@testing-library/user-event/dist/clear'

const UPDATE_INTERVAL_MS = 5000

function TimeRemaining({ units, _soc, _solar, _grid, _home, _battery }) {
  let refreshHandle = null
  const [capacity, setCapacity] = useState(null)

  const [minutesRemaining, setMinutesRemaining] = useState(null)
  const [timeRemaining, setTimeRemaining] = useState(null)

  useEffect(() => {
    return () => clearTimeout(refreshHandle)
  }, [])

  useEffect(() => {
    // sum total capacity of units
    let capacityBuffer = 0
    units.forEach((u) => {
      const { batteryCapacity } = parseModelCode(u.model)
      capacityBuffer += batteryCapacity
    })

    setCapacity(capacityBuffer)
  }, [units])

  useEffect(() => {
    // if demand covered by solar (or 0) we would get NaN
    let _minutesRemaining = -1
    const date = new Date()
    const hour = date.getHours()
    // if daylight, calculate assuming solar will continue
    // if late, calculate assuming no solar

    let _assumed_home = -1
    if (hour <= 14) {
      // TODO: cutoff in config?
      // calculate how much the house will need from battery if powered by battery and solar
      // consumption not satisfied by solar: from grid and battery, if positive
      // a little pessimisic: this will assume that exported solar is wasted and SoC will not increase
      // but SoC should reach 100% if override is active and export will be wasted
      // assumes solar will stay on this level
      _assumed_home = Math.max(0, parseFloat(_grid) + parseFloat(_battery))
    } else {
      // calculate how much the house will need from battery if powered by battery only
      _assumed_home = parseFloat(_home) // total consumption, including consumed solar
    }
    // we assume that SoC is already normalized to 0-100 range and full capacity is available
    // only for PV5+
    const battery_capacity_modifier = 1.0 // TODO: config?

    // _minutesRemaining = hour
    if (parseFloat(_assumed_home) >= 0.1) _minutesRemaining = ((capacity * battery_capacity_modifier * parseInt(_soc)) / parseFloat(_assumed_home) / 100) * 60

    if (_minutesRemaining > 0) setMinutesRemaining(_minutesRemaining)
    else setMinutesRemaining(null)
  }, [_soc, _solar, _grid, _home, _battery])

  useEffect(() => {
    function convertMinutes(totalMinutes) {
      const days = Math.floor(totalMinutes / 1440) // 1440 minutes in a day
      const remainingMinutesAfterDays = totalMinutes % 1440
      const hours = Math.floor(remainingMinutesAfterDays / 60) // 60 minutes in an hour
      const minutes = remainingMinutesAfterDays % 60
      return { days, hours, minutes }
    }

    if (minutesRemaining) setTimeRemaining(convertMinutes(minutesRemaining))
    else setTimeRemaining(null)
  }, [minutesRemaining])

  return (
    <>
      {timeRemaining !== null && (
        <div className="TimeRemaining">
          <p>
            <center>
              <Icon name="warning sign" color="orange" />
              You are off Grid as a power cut is detected.
              <br />
              Gateway has switched your home power to the battery.
              <br />
              <br />
              <strong>Estimated remaining battery time at your current usage:</strong>
            </center>
          </p>
          {/* {/* <pre>capacity: {capacity} kWh</pre>
          <pre>soc: {_soc}</pre> */}
          <div className="numbers">
            <div className="number-container">
              <div className="number">{timeRemaining.days}</div>
              <span className="label">days</span>
            </div>
            <div className="number-container">
              <div className="number">{timeRemaining.hours}</div>
              <span className="label">hours</span>
            </div>
            <div className="number-container">
              <div className="number">{timeRemaining.minutes}</div>
              <span className="label">minutes</span>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export { TimeRemaining }
